import React, {useEffect, useState} from 'react';
import styles from './SLSA.module.scss';

type Question = {
    title: string;
    topic: string;
    description: string;
    level: number;
    children: Question[];
    parents: Question[];
    answer: boolean;
    answered: boolean;
    toanswer: boolean;
}

const downloadCSV = (data : Question[], version: string, level: number) => {
    const csvRows: string[] = [];

    // Header row
    csvRows.push(`Version,${version},Assesment Level,${level}`);

    csvRows.push("Topic,Title,Level,Answer");

    // Add questions, selected options, and solutions to the CSV
    data.forEach((q) => {
        const row = [
            `"${q.topic}"`,
            `"${q.title}"`,
            `"${q.level}"`,
            `"${q.answered ? q.answer : "not reached"}"`,
        ];
        csvRows.push(row.join(","));
    });

    // Convert array to a single string
    const csvData = csvRows.join("\n");

    // Create Blob object
    const blob = new Blob([csvData], { type: "text/csv" });

    // Create download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `SLSA_${version}_results.csv`);

    // Append to DOM, trigger click, remove
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

function SLSA() {
    const [version, setVersion] = useState<string>("");
    const [raw, setRaw] = useState<any>();  // raw data from json
    const [data, setData] = useState<Question[]>([]);     // processed data organized in an array of Questions
    const [position, setPosition] = useState<number>(0);    // current position in the array of Questions
    const [finished, setFinished] = useState<boolean>(false);
    const [level, setLevel] = useState<number>(4);    // current level of the SLSA model [1, 2, 3, 4]
    const [topics, setTopics] = useState<string[]>([]);


    // fetch data from json file
    useEffect(() => {
        fetch('data.json')
            .then((res) => res.json())
            .then((data) => {
                setRaw(data.slsa);
                setVersion(data.version);
            });
    }, []);


    // process data from raw to array of Questions
    useEffect(() => {
        if (raw) {
            const data: Question[] = [];
            const topics: string[] = [];
            Object.entries(raw as Record<string, any>).forEach(([key_section, section]) => {
                const topic = key_section;
                if (!topics.includes(topic)) {
                    topics.push(topic);
                }

                Object.entries(section as Record<string, any>).forEach(([key_question, question]) => {
                    data.push({
                        title: key_question,
                        topic: topic,
                        description: question.description,
                        level: question.level,
                        children: [],
                        parents: question.parents.map((parent: string) => data.find((q: Question) => q.title === parent) as Question),
                        answer: false,
                        answered: false,
                        toanswer: true
                    });

                    question.parents.forEach((parent: string) => {
                        data.find((q: Question) => q.title === parent)?.children.push(data.find((q: Question) => q.title === key_question) as Question);
                    })
                })
            })

            setData(data);
            setTopics(topics);
        }
    }, [raw]);

    const answer = (answer: boolean) => {
        const newData = [...data];

        newData[position].answer = answer;
        newData[position].answered = true;
        newData[position].toanswer = false;

        setData(newData);

        if (!answer) {
            newData[position].children.forEach((child: Question) => {
                child.toanswer = false;
            });
            const ind = newData.findIndex((q: Question) => !q.answered && q.toanswer);
            if (ind !== -1) {
                setPosition(newData.findIndex((q: Question) => !q.answered && q.toanswer));
            } else {
                setFinished(true);
            }
        } else {
            if (newData[position].children.length > 0 && !newData[position].children[0].answered && newData[position].children[0].toanswer) {
                setPosition(newData.indexOf(newData[position].children[0]));
            } else {
                const ind = newData.findIndex((q: Question) => !q.answered && q.toanswer);
                if (ind !== -1) {
                    setPosition(newData.findIndex((q: Question) => !q.answered && q.toanswer));
                } else {
                    setFinished(true);
                }
            }
        }
    }

    // calculate slsa level
    useEffect(() => {
        let l = level
        if (finished) {
            data.filter((q: Question) => !q.answer).forEach((q: Question) => {
                if (q.level < l) {
                    l = q.level - 1;
                }
            });
        }
        setLevel(l);
    }, [finished]);


    return (
        <>
            <div className={styles.title}>
                <h1>SLSA Maturity Model</h1>
            </div>

            {data.length > 0 &&
                (!finished ?
                    <div className={styles.question}>
                        <div className={styles.question__header}>
                            <div className={styles.question__title}>{data[position].title}</div>
                            <div className={styles.question__topic}>{data[position].topic}</div>
                        </div>
                        <div dangerouslySetInnerHTML={{__html: data[position].description}} className={styles.question__description}></div>
                        <div className={styles.question__buttons}>
                            <button className={styles.question__button} onClick={() => answer(true)}>Yes</button>
                            <button className={styles.question__button} onClick={() => answer(false)}>No</button>
                        </div>
                    </div>
                :
                    <div className={styles.content}>
                        <div className={styles.levelsContainer}>
                            <div className={styles.levelsContainer__ladder}>
                                <div className={styles.levelsContainer__level}>
                                    <div className={`${styles.levelsContainer__level__box} ${styles.level4}`}>Level 4</div>
                                    <div style={{visibility: level === 4 ? "visible" : "hidden"}} className={styles.levelsContainer__level__arrow}>Sei qui</div>
                                </div>
                                <div className={styles.levelsContainer__level}>
                                    <div className={`${styles.levelsContainer__level__box} ${styles.level3}`}>Level 3</div>
                                    <div style={{visibility: level === 3 ? "visible" : "hidden"}} className={styles.levelsContainer__level__arrow}>Sei qui</div>
                                </div>
                                <div className={styles.levelsContainer__level}>
                                    <div className={`${styles.levelsContainer__level__box} ${styles.level2}`}>Level 2</div>
                                    <div style={{visibility: level === 2 ? "visible" : "hidden"}} className={styles.levelsContainer__level__arrow}>Sei qui</div>
                                </div>
                                <div className={styles.levelsContainer__level}>
                                    <div className={`${styles.levelsContainer__level__box} ${styles.level1}`}>Level 1</div>
                                    <div style={{visibility: level === 1 ? "visible" : "hidden"}} className={styles.levelsContainer__level__arrow}>Sei qui</div>
                                </div>
                                <div className={styles.levelsContainer__level}>
                                    <div className={`${styles.levelsContainer__level__box} ${styles.level0}`}>Level 0</div>
                                    <div style={{visibility: level === 0 ? "visible" : "hidden"}} className={styles.levelsContainer__level__arrow}>Sei qui</div>
                                </div>
                            </div>
                        </div>
                        {level !== 4 &&
                            <div className={styles.upgrades}>
                                <>
                                    <h3>Per raggiungere il livello {level + 1}, è necessario implementare:</h3>
                                    {topics.map(topic => {
                                        return (data.filter(q => q.topic === topic && q.level - 1 === level && !q.answer).length > 0 &&
                                            <div key={topic} className={styles.upgrades__cont}>
                                                <div className={styles.upgrades__topic}>{topic}</div>
                                                <div>
                                                    {data.filter(q => q.topic === topic && q.level - 1 === level && !q.answer).map((question, index) => (
                                                        <div key={question.title} className={styles.upgrades__question}>
                                                            <div className={styles.upgrades__question__title}>{question.title}</div>
                                                            <div dangerouslySetInnerHTML={{__html: question.description}} className={styles.upgrades__question__description}></div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    )}
                                </>
                            </div>
                        }

                        <div className={styles.downloadContainer}>
                            <button className={styles.question__button} onClick={() => downloadCSV(data, version, level)}>Download Answers</button>
                        </div>

                    </div>
                )
            }
        </>
    );
}

export default SLSA;

