import React, {useEffect, useState} from 'react';
import styles from './Devops.module.scss';

const development = process.env.REACT_APP_DEVELOPMENT === "true";

type Option = {
    id: number;
    option: string;
    selected: boolean;
}

type Solution = {
    id: number;
    solution: string;
}

type Question = {
    id: string;
    question: string;
    options: Option[];
    solutions: Solution[];
}

const downloadCSV = (data : Question[], version: string, level: number) => {
    const csvRows: string[] = [];

    // Header row
    csvRows.push(`Version,${version},Assesment Level,${level}`);

    csvRows.push("Question,Level,Selected Option");

    // Add questions, selected options, and solutions to the CSV
    data.forEach((q) => {
        const selectedOption = q.options.find((o) => o.selected);
        if (selectedOption) {
            const row = [
                `"${q.question}"`,
                `"${selectedOption.id}"`,
                `"${selectedOption.option}"`,
            ];
            csvRows.push(row.join(","));
        }
    });

    // Convert array to a single string
    const csvData = csvRows.join("\n");

    // Create Blob object
    const blob = new Blob([csvData], { type: "text/csv" });

    // Create download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `devops_${version}_results.csv`);

    // Append to DOM, trigger click, remove
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};


function Devops() {
    const [version, setVersion] = useState<string>("");
    const [raw, setRaw] = useState<any>();  // raw data from json
    const [data, setData] = useState<Question[]>([]);     // processed data organized in an array of Questions
    const [position, setPosition] = useState<number>(development ? 22 : 0);    // current position in the array of Questions
    const [level, setLevel] = useState<number>(5);          // current level of maturity
    const [selected, setSelected] = useState<number>(-1);   // selected option for the current question
    const [finished, setFinished] = useState<boolean>(false);   // flag to indicate if the test is finished

    console.log(development, process.env.REACT_APP_DEVELOPMENT)

    // fetch data from json file
    useEffect(() => {
        fetch('data.json')
            .then((res) => res.json())
            .then((data) => {
                setRaw(data.devops);
                setVersion(data.version);
            });
    }, []);

    // process data from raw to array of Questions
    useEffect(() => {
        if (raw) {
            const data: Question[] = [];

            Object.entries(raw as Record<string, any>).forEach(([key_question, question]) => {
                data.push({
                    id: key_question[0],
                    question: key_question,
                    options: Object.entries(question.options as Record<string, string>).map(([key_option, option]) => ({
                        id: key_option as unknown as number,
                        option: option,
                        selected: false
                    })),
                    solutions: Object.entries(question.solutions as Record<string, string>).map(([key_solution, solution]) => ({
                        id: key_solution as unknown as number,
                        solution: solution,
                    }))
                });
            })
            setData(data);
        }
    }, [raw]);


    // select an option for the current question
    const selectOption = (optionIndex: number) => {
        const newData = [...data];      // copy the array of Questions - this is needed in react to trigger the re-render
        newData[position].options.forEach((option, idx) => {
            option.selected = idx === optionIndex;
        });
        setSelected(optionIndex);
        setData(newData);
    }

    // go to the next question and set the level of maturity the minimum between the previous value and the option selected for this question
    const goToNextQuestion = () => {
        setLevel(Math.min(data[position].options[selected].id, level));
        setPosition(position + 1);
        setSelected(-1);
    }

    const finish = () => {
        setFinished(true);
        goToNextQuestion();
    }

    return (
        <>
            <div className={styles.title}>
                <h1>Devops Maturity Model</h1>
            </div>

            {data && data[position] && !finished &&
                <div className={styles.content}>
                    <h3>{data[position].question}</h3>
                    {data[position].options.map((option, index) => (
                        <div key={option.id} className={styles.option}>
                            <input className={styles.radio} type="radio" name="option" checked={option.selected}
                                   onChange={() => {
                                       selectOption(index)
                                   }}/>
                            <label onClick={() => {
                                selectOption(index)
                            }}>{option.id} - {option.option}</label>
                        </div>
                    ))}
                    <div className={styles.next_button_container}>
                        {position < data.length - 1 ?
                            <button className={styles.next_button} onClick={goToNextQuestion}
                                    disabled={selected === -1}>Next</button>
                            :
                            <button className={styles.next_button} onClick={finish} disabled={selected === -1}>Finish</button>
                        }
                    </div>
                </div>
            }

            {finished &&
                <div className={styles.content}>
                    <div className={styles.levelsContainer}>
                        <div className={styles.levelsContainer__ladder}>
                            <div className={styles.levelsContainer__level}>
                                <div className={`${styles.levelsContainer__level__box} ${styles.level5}`}>Level 5</div>
                                <div style={{visibility: level === 5 ? "visible" : "hidden"}} className={styles.levelsContainer__level__arrow}>Sei qui</div>
                            </div>
                            <div className={styles.levelsContainer__level}>
                                <div className={`${styles.levelsContainer__level__box} ${styles.level4}`}>Level 4</div>
                                <div style={{visibility: level === 4 ? "visible" : "hidden"}} className={styles.levelsContainer__level__arrow}>Sei qui</div>
                            </div>
                            <div className={styles.levelsContainer__level}>
                                <div className={`${styles.levelsContainer__level__box} ${styles.level3}`}>Level 3</div>
                                <div style={{visibility: level === 3 ? "visible" : "hidden"}} className={styles.levelsContainer__level__arrow}>Sei qui</div>
                            </div>
                            <div className={styles.levelsContainer__level}>
                                <div className={`${styles.levelsContainer__level__box} ${styles.level2}`}>Level 2</div>
                                <div style={{visibility: level === 2 ? "visible" : "hidden"}} className={styles.levelsContainer__level__arrow}>Sei qui</div>
                            </div>
                            <div className={styles.levelsContainer__level}>
                                <div className={`${styles.levelsContainer__level__box} ${styles.level1}`}>Level 1</div>
                                <div style={{visibility: level === 1 ? "visible" : "hidden"}} className={styles.levelsContainer__level__arrow}>Sei qui</div>
                            </div>
                        </div>
                    </div>
                    {level !== 5 &&
                        <div className={styles.upgrades}>
                            <h3>Per raggiungere il livello {level + 1} devi:</h3>
                            {data.filter(q => q.options.findIndex(o => o.selected) < level).map((question, index) => (
                                <div key={index} style={{marginTop: 30}}>
                                    <h4>{question.question}</h4>
                                    <div style={{marginLeft: 20}}>{question.solutions[level].solution}</div>
                                </div>
                            ))}
                        </div>
                    }

                    <div className={styles.downloadContainer}>
                        <button className={styles.next_button} onClick={() => downloadCSV(data, version, level)}>Download Answers</button>
                    </div>
                </div>
            }
        </>
    );
}

export default Devops;

