import React, {useState} from 'react';
import './App.scss';
import Devops from "./Devops/Devops";
import SLSA from "./SLSA/SLSA";


function App() {
    const [type, setType] = useState<string>("null");

    return (
        <div className="App">

            {type === "null" ?
                <div className="buttons">
                    <button className="button" onClick={() => setType("devops")}>Devops</button>
                    <button className="button" onClick={() => setType("SLSA")}>SLSA</button>
                </div>
            :
            (type === "devops" ?
                <Devops />
                    :
                <SLSA />
            )}

        </div>
    );
}

export default App;

